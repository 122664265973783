import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import { setIsLeavingPage } from 'state/actions'
import { sizes } from 'styles'
import { FOOTER_HEIGHT, GLOBAL_NAV_HEIGHT, NAV_HEIGHT } from 'src/consts'

const StyledContainer = styled(animated.div)`
  position: relative;
  box-sizing: border-box;
  max-width: 1400px;
  min-height: calc(100vh - ${FOOTER_HEIGHT}px);
  padding: 20px;
  padding-top: calc(${GLOBAL_NAV_HEIGHT}px + ${NAV_HEIGHT}px + 20px);
  margin: 0 auto;

  @media ${sizes.tabletPortrait} {
    padding: 40px;
    padding-top: calc(${GLOBAL_NAV_HEIGHT}px + ${NAV_HEIGHT}px + 40px);
  }

  @media ${sizes.desktop} {
    padding: 60px;
    padding-top: calc(${GLOBAL_NAV_HEIGHT}px + ${NAV_HEIGHT}px + 80px);
  }
`

const Container = ({ children, className }) => {
  const dispatch = useDispatch()
  const isLeavingPage = useSelector((state) => state.isLeavingPage)

  const [props, animate] = useSpring(() => ({
    config: { mass: 1, tension: 350, friction: 26 },
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 20px, 0px)' },
  }))

  useEffect(() => {
    if (isLeavingPage) {
      animate.start({ opacity: 0, transform: 'translate3d(0px, 20px, 0px)' })
      return
    }
    animate.start({ opacity: 1, transform: 'translate3d(0px, 0px, 0px)' })
  }, [isLeavingPage])

  useEffect(() => {
    dispatch(setIsLeavingPage(false))
  }, [])

  return (
    <StyledContainer style={props} className={className}>
      {children}
    </StyledContainer>
  )
}

export default Container
